import React from "react";
import { Layout, Menu } from "antd";
import "./style.css";
import { Link } from "react-router-dom";

const LeftSideBar = () => {
  return (
    <Layout.Sider theme="dark" className="sider">
      <Menu theme="dark" mode="inline">
        <Link className="sidebar_item" to={"/about"}>
          О компании
        </Link>
        <Link className="sidebar_item" to={"/contact"}>
          Контакты
        </Link>
        <Link className="sidebar_item" to={"/agreement"}>
          Пользовательское соглашение
        </Link>
        <Link className="sidebar_item" to={"/privacy"}>
          Соглашение о конфиденциальности
        </Link>
        <Link className="sidebar_item" to={"/offer"}>
          Договор оферта
        </Link>
        <Link className="sidebar_item" to={"/payment"}>
          Способы оплаты
        </Link>
      </Menu>
    </Layout.Sider>
  );
};

export default LeftSideBar;
