import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  List,
  Image,
  Button,
  Typography,
  Select,
  ConfigProvider,
  Form,
  Input,
} from "antd";
import { addToCart } from "../../actions/cartActions";
import { SmileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import axios from "axios";
import "./style.css";

const customizeRenderEmpty2 = () => (
  <div style={{ textAlign: "center", color: "black" }}>
    <SmileOutlined style={{ fontSize: "43px", margin: "20px 0" }} />
    <p style={{ fontSize: "23px" }}>Товаров сейчас нет</p>
  </div>
);

const { Option } = Select;

// const data = [
//   {
//     id: 1,
//     name:
//       "\u043a\u043b\u044e\u0447 \u043a \u0434\u043e\u043c\u043e\u0444\u043e\u043d\u0443 Touch memory \u043f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0437\u0430\u043f\u0440\u043e\u0433\u0440\u0430\u043c\u043c\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439",
//     amount: 500.0,
//     photo: "/api/media/media/keys_touch_memory.jpg",
//   },
//   {
//     id: 2,
//     name:
//       "\u0417\u0430\u043c\u0435\u043d\u0430 \u0442\u0440\u0443\u0431\u043a\u0438 \u043a \u0434\u043e\u043c\u043e\u0444\u043e\u043d\u0443",
//     amount: 1500.0,
//     photo: "/api/media/media/change_trubcka.jpg",
//   },
//   {
//     id: 3,
//     name:
//       "\u0423\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u0430 \u043d\u043e\u0432\u043e\u0439 \u0442\u0440\u0443\u0431\u043a\u0438 \u043a \u0434\u043e\u043c\u043e\u0444\u043e\u043d\u0443",
//     amount: 2000.0,
//     photo: "/api/media/media/change_trubcka_bS3Jbd0.jpg",
//   },
//   {
//     id: 4,
//     name:
//       "\u041f\u043e\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u0438\u0435 \u0432\u0438\u0434\u0435\u043e\u043c\u043e\u043d\u0438\u0442\u043e\u0440\u0430 \u043a \u0434\u043e\u043c\u043e\u0444\u043e\u043d\u0443",
//     amount: 4000.0,
//     photo: "/api/media/media/domofon_video.jpg",
//   },
//   {
//     id: 5,
//     name:
//       "\u0432\u0438\u0434\u0435\u043e\u043c\u043e\u043d\u0438\u0442\u043e\u0440 \u0441 \u0443\u0441\u0442\u0430\u043d\u043e\u0432\u043a\u043e\u0439",
//     amount: 6900.0,
//     photo: "/api/media/media/domofon_video_lktTcuG.jpg",
//   },
//   {
//     id: 6,
//     name:
//       "\u0417\u0430\u043c\u0435\u043d\u0430 \u0438\u043d\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043b\u044c\u043d\u043e\u0433\u043e \u043a\u043e\u0434\u0430",
//     amount: 100.0,
//     photo: "/api/media/media/houses.jpg",
//   },
//   {
//     id: 7,
//     name:
//       "\u0412\u044b\u0435\u0437\u0434 \u0441\u043f\u0435\u0446\u0438\u0430\u043b\u0438\u0441\u0442\u0430",
//     amount: 500.0,
//     photo: "/api/media/media/%D1%89%D0%B3%D0%B5.png",
//   },
//   {
//     id: 8,
//     name: "Оплата по счету",
//     amount: 0.0,
//     photo: "/api/media/media/%D1%89%D0%B3%D0%B5.png",
//     bill: true,
//   },
// ];

const CatalogPage = ({ addToCart }) => {
  const [form] = Form.useForm();
  const [itemCount, setItemCount] = useState([]);
  const [content, setContent] = useState();
  const [state, setState] = useState({});
  const handleChange = (id, value) => {
    setItemCount([...itemCount, { id: id, count: value }]);
  };
  const addItem = (item) => {
    const count = itemCount.find((i) => i.id === item.id);
    count ? (item.count = count.count * 1) : (item.count = 1);
    console.log("ADD ITEM", item);
    addToCart(item);
  };
  const inputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(async () => {
    const response = await axios.get("/api/get_products/");
    setContent(response.data.products);
  }, []);

  const onClickHandler = (item) => {
    console.log("Item", item);
    console.log("state", state);
    item.count = 1;
    item.number = state.number;
    item.amount = state.amount;
    console.log("FORMATED ITEM", item);
    addToCart(item);
  };
  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={22}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            Каталог товаров
          </Typography.Title>
          <ConfigProvider renderEmpty={customizeRenderEmpty2}>
            <List
              dataSource={content}
              temLayout="horizontal"
              bordered={true}
              style={{ marginBottom: "10px" }}
              locale={{ emptyText: "Cart is empty" }}
              renderItem={(item) => {
                const { bill } = item;
                if (bill) {
                  return (
                    <List.Item>
                      <List.Item.Meta
                        style={{ alignItems: "center", marginRight: "20px" }}
                        avatar={
                          <Image
                            width={"100px"}
                            height={"100px"}
                            src={item.photo}
                          />
                        }
                        title={item.name}
                      />
                      <Form
                        form={form}
                        name="bill"
                        onFinish={() => onClickHandler(item)}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            marginRight: "15px",
                          }}
                        >
                          <Form.Item
                            name="number"
                            label="Номер счета"
                            rules={[
                              {
                                required: true,
                                message: "Введите номер счета.",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input
                              name="number"
                              size="large"
                              type="number"
                              required
                              className={"product-input-number"}
                              value={state.number}
                              autoComplete="new-password"
                              onChange={(e) => inputChange(e)}
                            />
                          </Form.Item>

                          <Form.Item
                            name="amount"
                            label="Сумма счета"
                            rules={[
                              {
                                required: true,
                                message: "Введите сумму",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input
                              name="amount"
                              size="large"
                              type="number"
                              required
                              className={"product-input-number"}
                              value={state.amount}
                              onChange={(e) => inputChange(e)}
                              autoComplete="new-password"
                            />
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button size={"large"} htmlType={"submit"}>
                              Добавить в корзину
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </List.Item>
                  );
                } else {
                  return (
                    <List.Item>
                      <List.Item.Meta
                        style={{ alignItems: "center" }}
                        avatar={
                          <Image
                            width={"100px"}
                            height={"100px"}
                            src={item.photo}
                          />
                        }
                        title={item.name}
                      />
                      <div className="amount-count">
                        <div className="product_price">
                          Цена: {item.amount}р.
                        </div>
                        <div className="product_count">
                          Количество:{" "}
                          <Select
                            defaultValue="1"
                            className="select"
                            onChange={(value) => handleChange(item.id, value)}
                          >
                            <Option value="1" className={"select-option"}>
                              1
                            </Option>
                            <Option value="2" className={"select-option"}>
                              2
                            </Option>
                            <Option value="3" className={"select-option"}>
                              3
                            </Option>
                            <Option value="4" className={"select-option"}>
                              4
                            </Option>
                            <Option value="5" className={"select-option"}>
                              5
                            </Option>
                            <Option value="6" className={"select-option"}>
                              6
                            </Option>
                            <Option value="7" className={"select-option"}>
                              7
                            </Option>
                            <Option value="8" className={"select-option"}>
                              8
                            </Option>
                            <Option value="9" className={"select-option"}>
                              9
                            </Option>
                            <Option value="10" className={"select-option"}>
                              10
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <Button size={"large"} onClick={() => addItem(item)}>
                        Добавить в корзину
                      </Button>
                    </List.Item>
                  );
                }
              }}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cartReducer,
});

export default connect(mapStateToProps, { addToCart })(CatalogPage);
