import { message } from "antd";
import {
  CLEAR_CART,
  CLEAR_ORDERS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
} from "./types";
import axios from "axios";
import { clearCart } from "./cartActions";
import { clearOrders } from "./orderActions";

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: SIGN_IN,
  });
  const formData = {
    email: email,
    password: password,
  };
  try {
    const response = await axios.post("/api/authorization_user/", formData);
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: response.data,
    });
    message.success({
      content: "Добро пожаловать.",
      style: {
        fontSize: "23px",
      },
    });
    localStorage.setItem("user", JSON.stringify(response.data.email));
    localStorage.setItem("token", JSON.stringify(response.data.token));
    localStorage.setItem("auth", true);
  } catch (err) {
    dispatch({
      type: SIGN_IN_FAILURE,
      payload: err,
    });
    message.error("Неверное имя пользователя или пароль.");
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("auth");
  dispatch({
    type: CLEAR_CART,
  });
  dispatch({
    type: CLEAR_ORDERS,
  });
  dispatch({
    type: SIGN_OUT,
  });
};

export const register = (email, password) => async (dispatch) => {
  dispatch({
    type: SIGN_UP,
  });
  const formData = {
    email: email,
    password: password,
  };
  try {
    const response = await axios.post("/api/registration_user/", formData);
    dispatch({
      type: SIGN_UP_SUCCESS,
      payload: response.data,
    });
    message.success({
      content: "Добро пожаловать.",
      style: {
        fontSize: "23px",
      },
    });
    localStorage.setItem("user", JSON.stringify(response.data.email));
    localStorage.setItem("token", JSON.stringify(response.data.token));
    localStorage.setItem("auth", true);
  } catch (err) {
    dispatch({
      type: SIGN_UP_FAILURE,
      payload: err,
    });
    message.error("Этот e-mail уже зарегестрирован.");
  }
};
