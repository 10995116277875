import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
} from "../actions/types";

const userFromStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const tokenFromStorage = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : null;
const authFromStorage = localStorage.getItem("auth")
  ? JSON.parse(localStorage.getItem("auth"))
  : false;

const initialState = {
  user: userFromStorage,
  loading: false,
  isAuthenticated: authFromStorage,
  token: tokenFromStorage,
};
// const initialState = {
//   user: "n@m.ru",
//   loading: false,
//   isAuthenticated: true,
//   token: "vasdffa214czd",
// };

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIGN_IN:
    case SIGN_UP:
      return {
        ...state,
        loading: true,
      };
    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        user: payload.email,
        token: payload.token,
        loading: false,
        isAuthenticated: true,
      };
    case SIGN_UP_FAILURE:
    case SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        token: null,
      };
    default:
      return state;
  }
}
