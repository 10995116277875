import { ADD_TO_CART, CLEAR_CART, REMOVE_FROM_CART } from "../actions/types";
const cartFromStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const initialState = {
  items: cartFromStorage,
};

export default function cartReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TO_CART:
      const existItem = state.items.find((x) => x.id === payload.id);
      if (existItem) {
        return {
          ...state,
          items: state.items.map((x) => (x.id === existItem.id ? payload : x)),
        };
      } else {
        return {
          ...state,
          items: [...state.items, payload],
        };
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter((x) => x.id !== payload.id),
      };
    case CLEAR_CART:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
}
