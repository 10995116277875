import React, { Fragment, useEffect, useState } from "react";
import { Layout, Row, Col, Typography, List, Image, Button } from "antd";
import { connect } from "react-redux";
import { getOrders } from "../../actions/orderActions";
import { logout } from "../../actions/authActions";
import { clearCart } from "../../actions/cartActions";

const ProfilePage = ({
  order: { orders },
  getOrders,
  logout,
  auth: { token },
  match,
  clearCart,
}) => {
  useEffect(() => {
    getOrders(token);
    if (match.params.clear === "clear") {
      clearCart();
    }
  }, []);
  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={21}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            История Заказов
          </Typography.Title>
        </Col>
        <Col xs={2}>
          <Button
            size="large"
            color="red"
            style={{ marginTop: "20px", marginLeft: "-40px" }}
            onClick={logout}
          >
            Выйти
          </Button>
        </Col>
        {orders.length > 0 ? (
          orders.map((order) => {
            const { products } = order;
            return (
              <Col
                xs={22}
                style={{
                  border: "2px solid gainsboro",
                  padding: "15px",
                  marginBottom: "10px",
                  borderRadius: "4px",
                }}
              >
                <Col
                  xs={24}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginBottom: "30px",
                  }}
                >
                  <Typography.Title level={2}>
                    Номер заказа №{order.id}
                  </Typography.Title>
                  <Typography.Title level={3} className={"user-info-title"}>
                    Личные данные
                  </Typography.Title>
                  <Row className={"user-info-description"}>
                    <Col>
                      Имя Фамилия: {order.first_name} {order.second_name}
                    </Col>
                    <Col>Телефон: {order.phone}</Col>
                    <Col>
                      Адрес: {order.city}, {order.address}
                    </Col>
                    <Col>
                      Статус платежа: {order.payment ? (<span style={{ color: "green" }}>Оплачено</span>) : (<span style={{ color: "red" }}>Не оплачено</span>)}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Typography.Title level={2} className={"user-info-title"}>
                    Товары
                  </Typography.Title>
                  <List
                    dataSource={products}
                    temLayout="horizontal"
                    bordered={true}
                    renderItem={(item) => {
                      const { bill } = item;
                      if (bill) {
                        return (
                          <List.Item>
                            <List.Item.Meta
                              style={{ alignItems: "center" }}
                              avatar={
                                <Image
                                  width={"100px"}
                                  height={"100px"}
                                  src={item.photo}
                                />
                              }
                              title={item.name}
                            />
                            <div className="amount-count">
                              <div className="product_price">
                                Номер счета: №{item.number_bill}
                              </div>
                              <div className="product_count">
                                Сумма счета: {item.amount_bill}р.
                              </div>
                            </div>
                            <div className="product_count">
                              Итог: {item.amount_bill * item.quantity}р.
                            </div>
                          </List.Item>
                        );
                      } else {
                        return (
                          <List.Item>
                            <List.Item.Meta
                              style={{ alignItems: "center" }}
                              avatar={
                                <Image
                                  width={"100px"}
                                  height={"100px"}
                                  src={item.photo}
                                />
                              }
                              title={item.name}
                            />
                            <div className="amount-count">
                              <div className="product_price">
                                Цена: {item.amount}р.
                              </div>
                              <div className="product_count">
                                Количество: {item.quantity}
                              </div>
                            </div>
                            <div className="product_count">
                              Итог: {item.amount * item.quantity}р.
                            </div>
                          </List.Item>
                        );
                      }
                    }}
                  />
                </Col>
              </Col>
            );
          })
        ) : (
          <Typography.Title level={2}>
            Вы еще не соврешали заказов.
          </Typography.Title>
        )}
      </Row>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  order: state.orderReducer,
});

export default connect(mapStateToProps, { getOrders, logout, clearCart })(
  ProfilePage
);
