import React from "react";
import { Layout, Row, Col, Typography } from "antd";

const FailurePage = () => {
  return (
    <Layout.Content>
      <Row justify="center" align="center" className="main_content">
        <Col
          xs={22}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            Ваш заказ не был оплачен. Обратитесь к администрации сайта.
          </Typography.Title>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default FailurePage;
