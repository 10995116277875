import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Typography, message } from "antd";
import { ReactComponent as ApplePay } from "../../constants/Apple_Pay-Logo.wine.svg";
import { ReactComponent as GooglePay } from "../../constants/google-pay-primary-logo.svg";
import { ReactComponent as Maestro } from "../../constants/maestro-seeklogo.com.svg";
import { ReactComponent as MasterCard } from "../../constants/mc_vrt_pos.svg";
import { ReactComponent as Mir } from "../../constants/national-payment-system-mir.svg";
import { ReactComponent as Visa } from "../../constants/visa.svg";
import { ReactComponent as VisaElectron } from "../../constants/if-visa-electron-2593672_86617.svg";

const PaymentPage = () => {
  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={22}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 25px 0" }}
          >
            Способы оплаты
          </Typography.Title>
          <div>
            <h2 style={{ fontSize: "25px", fontWeight: "600" }}>
              Оплатить покупку в интернет-магазине можно следующим способом:
            </h2>
            <ul style={{ fontSize: "22px" }}>
              <li>
                <strong>Пластиковые карты</strong> <br /> Безналичная оплата
                банковской картой в магазине. Принимаются карты следующих
                платёжных систем: «VISA», «MasterCard», «Maestro», «МИР», с
                помощью Apple Pay или Google Pay
              </li>
              <li>
                <strong>Онлайн-оплата на сайте</strong> <br /> Оплата
                осуществляется через компанию-партнера — https://2can.ru
                Комиссия не взимается. Если вы выбираете онлайн-оплату - вы
                указываете информацию о своем платежном средстве в специальной
                защищенной форме оплаты на странице компании-партнера. Партнер
                проводит проверку сформированного запроса на соответствие
                установленном требованиям и передает этот запрос на авторизацию.
                После того, как банк-эмитент подтвердил авторизацию платежа, вы
                возвращаетесь на наш сайт на страницу подтверждения заказа. На
                номер телефона, указанный при оформлении заказа, высылается
                специальный код, который нужно будет сообщить сотрудникам
                магазина при получении заказа. Внимание! При возврате платежа
                денежные средства вернутся только на то же платежное средство, с
                которого был произведен платеж. Обращаем ваше внимание, что
                необходимо предоставить Заявление на возврат.
              </li>
            </ul>
          </div>
          <div
            className="edit_page"
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "50px",
            }}
          >
            <ApplePay
              style={{ width: "100px", height: "50px", margin: "10px" }}
            />
            <GooglePay
              style={{ width: "100px", height: "50px", margin: "10px" }}
            />
            <MasterCard
              style={{ width: "100px", height: "50px", margin: "10px" }}
            />
            <Maestro
              style={{ width: "100px", height: "50px", margin: "10px" }}
            />
            <Mir style={{ width: "100px", height: "50px", margin: "10px" }} />
            <Visa style={{ width: "100px", height: "50px", margin: "10px" }} />
            <VisaElectron
              style={{ width: "100px", height: "50px", margin: "10px" }}
            />
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default PaymentPage;
