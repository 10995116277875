import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import LeftSideBar from "../../layout/LeftSideBar";

const AppLayout = ({ children, ...rest }) => {
  return (
    <Fragment>
      <LeftSideBar />
      {children}
    </Fragment>
  );
};

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  );
};

export default AppLayoutRoute;
