import React, { useState } from "react";
import { Layout, Row, Col, Typography, Form, Input, Button } from "antd";
import { addUserInfo } from "../../actions/orderActions";
import { connect } from "react-redux";
import "./style.css";

const UserContactPage = ({ addUserInfo, history }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ city: "Москва" });
  console.log("state", state);
  const onClickHandler = () => {
    const formAddress = `г.${state.city}, ул.${state.street}, д.${
      state.house
    },${state.build ? `к.${state.build}` : ""}, кв.${state.apartment}`;
    console.log("address", formAddress);
    const formData = {
      first_name: state.first_name,
      second_name: state.second_name,
      phone: state.phone,
      address: formAddress,
      city: state.city,
    };
    addUserInfo(formData);
    history.push("/create_order");
  };
  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={22}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            Введите ваши личные данные
          </Typography.Title>
          <Form
            form={form}
            name="register"
            onFinish={onClickHandler}
            scrollToFirstError
          >
            <Form.Item
              name="first_name"
              label="Имя"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите Ваше Имя.",
                },
              ]}
              hasFeedback
            >
              <Input
                name="first_name"
                size="large"
                type="text"
                required
                value={state.first_name}
                autoComplete="new-password"
                onChange={(e) => onChangeHandler(e)}
              />
            </Form.Item>

            <Form.Item
              name="second_name"
              label="Фамилия"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите Вашу Фамилию.",
                },
              ]}
              hasFeedback
            >
              <Input
                name="second_name"
                size="large"
                type="text"
                required
                value={state.second_name}
                onChange={(e) => onChangeHandler(e)}
                autoComplete="new-password"
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Телефон"
              hasFeedback
              rules={[
                { required: true, message: "Пожалуйста, введите Ваш Номер!" },
              ]}
            >
              <Input
                name="phone"
                size="large"
                type="number"
                required
                min={1}
                style={{ width: "100%" }}
                autoComplete="new-password"
                className="disabled_scroll"
                value={state.phone}
                onChange={(e) => onChangeHandler(e)}
              />
            </Form.Item>

            <div className={"ant-row ant-form-item"}>
              <div className={"ant-col ant-form-item-label"}>
                <label className={"ant-form-item-required"}>Город</label>
              </div>
              <div className={"ant-col ant-form-item-control city_container"}>
                <span className="city">Москва</span>
              </div>
            </div>
            <Form.Item
              name="street"
              label="Улица"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите Вашу Улицу.",
                },
              ]}
            >
              <Input
                name="street"
                size="large"
                type="text"
                required
                value={state.street}
                autoComplete="new-password"
                onChange={(e) => onChangeHandler(e)}
              />
            </Form.Item>

            <Form.Item style={{ minHeight: "113px" }}>
              <Form.Item
                name="house"
                label="Дом"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите Ваш Дом.",
                  },
                ]}
              >
                <Input
                  name="house"
                  size="large"
                  type="text"
                  required
                  value={state.house}
                  autoComplete="new-password"
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Item>

              <Form.Item
                style={{ marginLeft: "10px" }}
                name="build"
                label="Корпус"
              >
                <Input
                  name="build"
                  size="large"
                  type="text"
                  value={state.build}
                  autoComplete="new-password"
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Item>

              <Form.Item
                style={{ marginLeft: "30px" }}
                name="apartment"
                label="Квартира"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите Вашу Квартиру.",
                  },
                ]}
              >
                <Input
                  name="apartment"
                  size="large"
                  type="text"
                  required
                  value={state.apartment}
                  autoComplete="new-password"
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" size="large" htmlType="submit">
                Продолжить
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  order: state.orderReducer,
});

export default connect(mapStateToProps, { addUserInfo })(UserContactPage);
