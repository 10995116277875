import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/common/routes/PrivateRoute";
import LoginPage from "./pages/Auth/LoginPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import AppLayoutRoute from "./components/common/routes/AppLayoutRoute";
import NotFoundPage from "./pages/NotFoundPage";
import CatalogPage from "./pages/CatalogPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import UserAgreementPage from "./pages/UserAgreementPage";
import PrivacyAgreementPage from "./pages/PrivacyAgreementPage";
import OfferAgreementPage from "./pages/OfferAgreementPage";
import CartPage from "./pages/CartPage";
import ProfilePage from "./pages/ProfilePage";
import UserContactPage from "./pages/UserContactPage";
import OrderPage from "./pages/OrderPage";
import FailurePage from "./pages/FailurePage";
import PaymentPage from "./pages/PaymentPage/PaymentPage";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <AppLayoutRoute exact path="/" component={CatalogPage} />
      <AppLayoutRoute exact path="/about" component={AboutPage} />
      <AppLayoutRoute exact path="/contact" component={ContactPage} />
      <AppLayoutRoute exact path="/agreement" component={UserAgreementPage} />
      <AppLayoutRoute exact path="/payment" component={PaymentPage} />
      <AppLayoutRoute exact path="/privacy" component={PrivacyAgreementPage} />
      <AppLayoutRoute exact path="/offer" component={OfferAgreementPage} />
      <AppLayoutRoute exact path="/cart" component={CartPage} />
      <PrivateRoute exact path="/profile" component={ProfilePage} />
      <PrivateRoute exact path="/profile/:clear" component={ProfilePage} />
      <PrivateRoute exact path="/user_info" component={UserContactPage} />
      <PrivateRoute exact path="/create_order" component={OrderPage} />
      <AppLayoutRoute exact path="/cancel_payment" component={FailurePage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
