import React, { useEffect, useState } from "react";
import { Layout, Typography, Form, Input, Button, Checkbox } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../../actions/authActions";
import "./style.css";

const { Title } = Typography;

const LoginPage = ({ login, location, history, auth: { user } }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (user) {
      history.push(redirect);
    }
  }, [history, user, redirect]);

  const submitHandler = () => {
    login(email, password);
  };

  return (
    <Layout.Content className="login_container">
      <Title className="login_title">Авторизация</Title>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        // onFinish={submitHandler}
        size="large"
      >
        <Form.Item
          name="e-mail"
          rules={[
            { required: true, message: "Пожалуйста, введите ваш E-mail!" },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder={"Введите ваш E-mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Пожалуйста, введите ваш Пароль!" },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={"Введите ваш пароль"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{ fontSize: "23px" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={submitHandler}
            style={{ marginRight: "10px" }}
          >
            Войти
          </Button>{" "}
          Или{" "}
          <Link to="/register" style={{ marginLeft: "10px" }}>
            {" "}
            зарегестрируйтесь сейчас!
          </Link>
        </Form.Item>
      </Form>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, { login })(LoginPage);
