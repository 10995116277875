import {
  TAKE_CART,
  ADD_USER_INFO,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  CREATE_ORDER_FAILURE,
  GET_ORDERS_FAILURE,
  CLEAR_ORDERS,
} from "./types";
import axios from "axios";

export const takeCart = (items) => (dispatch) => {
  dispatch({
    type: TAKE_CART,
    payload: items,
  });
};

export const addUserInfo = (info) => (dispatch) => {
  dispatch({
    type: ADD_USER_INFO,
    payload: info,
  });
};

export const createOrder = (order, token) => async (dispatch) => {
  dispatch({
    type: CREATE_ORDER,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.post("/api/new_order/", order, config);
    window.location.href = response.data.payment_url;
    dispatch({
      type: CREATE_ORDER_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: CREATE_ORDER_FAILURE,
      payload: err,
    });
  }
};

export const getOrders = (token) => async (dispatch) => {
  dispatch({
    type: GET_ORDERS,
  });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    const response = await axios.get("/api/get_orders/", config);
    dispatch({
      type: GET_ORDERS_SUCCESS,
      payload: response.data.orders,
    });
  } catch (err) {
    dispatch({
      type: GET_ORDERS_FAILURE,
    });
  }
};

export const clearOrders = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDERS,
  });
};
