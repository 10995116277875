import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Typography, message } from "antd";
import axios from "axios";

const UserAgreementPage = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState();
  useEffect(async () => {
    const body = {
      key: "users_agree",
    };
    try {
      const response = await axios.post("/api/get_static_page/", body);
      const { page } = response.data;
      setName(page.name);
      setDescription(page.content);
    } catch (err) {
      message.error("Ошибка сервера");
    }
  }, []);
  return (
    <Layout.Content>
      <Row justify="center" style={{ overflowY: "scroll", height: "50.3rem" }}>
        <Col xs={22}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            {name}
          </Typography.Title>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="edit_page"
          ></div>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default UserAgreementPage;
