import React from "react";
import { Layout, Menu, Badge } from "antd";
import { withRouter, NavLink } from "react-router-dom";
import {
  ShoppingCartOutlined,
  HomeOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import "./style.css";

const Header = ({ location, auth: { user }, cart: { items } }) => {
  return (
    <Layout.Header className="header">
      <div className="logo" />
      <div className="container_menu">
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["/"]}
          selectedKeys={[location.pathName]}
          className="menu"
        >
          <Menu.Item key="/" className="item">
            <NavLink to={"/"}>
              <div className="item_span">
                <ShoppingOutlined className="item_span_img" />
                Каталог товаров
              </div>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/cart" className="item">
            <NavLink to={"/cart"}>
              <div className="item_span">
                {" "}
                <Badge count={items.length}>
                  <ShoppingCartOutlined
                    className="item_span_img"
                    style={{ color: "hsla(0,0%,100%,.65) !important" }}
                  />
                </Badge>{" "}
                <span style={{ marginLeft: "10px" }}>Корзина</span>
              </div>
            </NavLink>
          </Menu.Item>
          {user ? (
            <Menu.Item key="/profile" className="item">
              <NavLink to={"/profile"}>
                <div className="item_span">
                  <UserOutlined className="item_span_img" />
                  Личный кабинет
                </div>
              </NavLink>
            </Menu.Item>
          ) : (
            <Menu.Item key="/login" className="item">
              <NavLink to={"/login"}>
                <div className="item_span">
                  <UserOutlined className="item_span_img" />
                  Авторизация
                </div>
              </NavLink>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </Layout.Header>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  cart: state.cartReducer,
});

export default connect(mapStateToProps, {})(withRouter(Header));
