import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Typography, List, Image, Button } from "antd";
import { connect } from "react-redux";
import { createOrder } from "../../actions/orderActions";

const OrderPage = ({ order, createOrder, auth: { token } }) => {
  const [totalPrice, setTotalPrice] = useState();
  const [formItems, setFormItems] = useState([]);
  const { items, userInfo } = order.order;
  useEffect(() => {
    let total = 0;
    let newItems = [];
    items.map((item) => {
      total = total + item.amount * item.count;
      if (item.bill) {
        return (newItems = [
          ...newItems,
          {
            id: item.id,
            count: item.count,
            amount: item.amount,
            number: item.number,
          },
        ]);
      } else {
        return (newItems = [...newItems, { id: item.id, count: item.count }]);
      }
    });
    setTotalPrice(total);
    setFormItems(newItems);
    console.log("FORM ITEMS", formItems);
  }, [items]);

  const onClickHandler = () => {
    const formData = {
      products: formItems,
      first_name: userInfo.first_name,
      second_name: userInfo.second_name,
      phone: userInfo.phone,
      address: userInfo.address,
      city: userInfo.city,
    };
    console.log("FORM DATA", formData);
    createOrder(formData, token);
  };

  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={22}>
          <Typography.Title
            style={{ textAlign: "center", marginTop: "25px 0px 10px 0" }}
          >
            Ваш Заказ
          </Typography.Title>
        </Col>
        <Col
          xs={22}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={2} className={"user-info-title"}>
            Личные данные
          </Typography.Title>
          <Row className={"user-info-description"}>
            <Col>
              Имя Фамилия: {userInfo.first_name} {userInfo.second_name}
            </Col>
            <Col>Телефон: {userInfo.phone}</Col>
            <Col>
              Адрес: {userInfo.city}, {userInfo.address}
            </Col>
          </Row>
        </Col>
        <Col xs={22}>
          <Typography.Title level={2} className={"user-info-title"}>
            Товары
          </Typography.Title>
          <List
            dataSource={items}
            temLayout="horizontal"
            bordered={true}
            renderItem={(item) => {
              const { bill } = item;
              if (bill) {
                return (
                  <List.Item>
                    <List.Item.Meta
                      style={{ alignItems: "center" }}
                      avatar={
                        <Image
                          width={"100px"}
                          height={"100px"}
                          src={item.photo}
                        />
                      }
                      title={item.name}
                    />
                    <div className="amount-count">
                      <div className="product_price">
                        Номер счета: №{item.number}
                      </div>
                      <div className="product_count">
                        Сумма счета: {item.amount}р.
                      </div>
                    </div>
                    <div className="product_count">
                      Итог: {item.amount * item.count}р.
                    </div>
                  </List.Item>
                );
              } else {
                return (
                  <List.Item>
                    <List.Item.Meta
                      style={{ alignItems: "center" }}
                      avatar={
                        <Image
                          width={"100px"}
                          height={"100px"}
                          src={item.photo}
                        />
                      }
                      title={item.name}
                    />
                    <div className="amount-count">
                      <div className="product_price">Цена: {item.amount}р.</div>
                      <div className="product_count">
                        Количество: {item.count}
                      </div>
                    </div>
                    <div className="product_count">
                      Итог: {item.amount * item.count}р.
                    </div>
                  </List.Item>
                );
              }
            }}
          />
        </Col>
        <Col
          xs={22}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          {items.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="product_total">Итого: {totalPrice}р.</div>
              <Button type="primary" size="large" onClick={onClickHandler}>
                Оплатить заказ
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  order: state.orderReducer,
  auth: state.authReducer,
});

export default connect(mapStateToProps, { createOrder })(OrderPage);
