import { message } from "antd";
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from "./types";

export const addToCart = (item) => (dispatch, getState) => {
  dispatch({
    type: ADD_TO_CART,
    payload: item,
  });
  message.success({
    content: "Товар добавлен в корзину",
    style: {
      fontSize: "23px",
    },
  });
  localStorage.setItem("cart", JSON.stringify(getState().cartReducer.items));
};

export const removeFromCart = (item) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: item,
  });

  localStorage.setItem("cart", JSON.stringify(getState().cartReducer.items));
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART,
  });
  localStorage.removeItem("cart");
};
