import React, { useState, useEffect } from "react";
import { Form, Layout, Input, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { register } from "../../../actions/authActions";
import "./style.css";

const { Title } = Typography;
const RegisterPage = ({ register, location, history, auth: { user } }) => {
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (user) {
      history.push(redirect);
    }
  }, [history, user, redirect]);

  const submitHandler = () => {
    register(email, password);
  };

  return (
    <Layout.Content className="login_container">
      <Title className="login_title">Регистрация</Title>
      <Form form={form} name="register" scrollToFirstError>
        <Form.Item
          style={{ fontSize: "23px" }}
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Некорректный E-mail!",
            },
            {
              required: true,
              message: "Пожалуйста, введите ваш E-mail!",
            },
          ]}
        >
          <Input
            placeholder={"Введите ваш E-mail"}
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш пароль!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder={"Введите ваш пароль"}
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Подтвердите пароль"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Пожалуйста, подтвердите ваш пароль!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject("Пароли не совпадают!");
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={"Подтвердите ваш пароль"}
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ fontSize: "23px" }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={submitHandler}
            style={{ marginRight: "10px" }}
          >
            Зарегестрироваться
          </Button>{" "}
          или{" "}
          <Link to="/login" style={{ marginLeft: "10px" }}>
            авторизуйтесь сейчас!
          </Link>
        </Form.Item>
      </Form>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, { register })(RegisterPage);
