import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import LeftSideBar from "../../layout/LeftSideBar";
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated && !loading ? (
        <Redirect to="/" />
      ) : (
        <Fragment>
          <LeftSideBar />
          <Component {...props} />
        </Fragment>
      )
    }
  />
);

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps)(PrivateRoute);
