import { Layout } from "antd";
import "antd/dist/antd.css";
import Footer from "./components/layout/Footer";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/layout/Header";
import Routes from "./Routes";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Layout>
            <Header />
            <Layout className="main_section">
              <Routes />
              {/* <Footer style={{ position: "fixed", bottom: 0 }} /> */}
            </Layout>
          </Layout>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
