import React, { Fragment, useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  List,
  Image,
  Button,
  ConfigProvider,
} from "antd";
import { removeFromCart } from "../../actions/cartActions";
import { SmileOutlined } from "@ant-design/icons";
import { takeCart } from "../../actions/orderActions";
import { connect } from "react-redux";

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center", color: "black" }}>
    <SmileOutlined style={{ fontSize: "43px", margin: "20px 0" }} />
    <p style={{ fontSize: "23px" }}>Ваша корзина пуста</p>
  </div>
);

const CartPage = ({
  cart: { items },
  removeFromCart,
  history,
  auth: { user },
  takeCart,
}) => {
  const [totalPrice, setTotalPrice] = useState();

  useEffect(() => {
    let total = 0;
    items.map((item) => {
      total = total + item.amount * item.count;
    });
    setTotalPrice(total);
  }, [items]);

  const addCartToOrder = () => {
    takeCart(items);
    user ? history.push("/user_info") : history.push("/login");
  };
  return (
    <Layout.Content>
      <Row justify="center" className="main_content">
        <Col xs={23}>
          <Typography.Title
            style={{ textAlign: "center", margin: "25px 0px 10px 0" }}
          >
            Корзина
          </Typography.Title>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <List
              dataSource={items}
              temLayout="horizontal"
              bordered={true}
              renderItem={(item) => {
                const { bill } = item;
                if (bill) {
                  console.log("BILL ITEM");
                  return (
                    <List.Item>
                      <List.Item.Meta
                        style={{ alignItems: "center" }}
                        avatar={
                          <Image
                            width={"100px"}
                            height={"100px"}
                            src={item.photo}
                          />
                        }
                        title={item.name}
                      />
                      <div className="amount-count">
                        <div className="product_price">
                          Номер счета: №{item.number}
                        </div>
                        <div className="product_count">
                          Сумма счета: {item.amount}р.
                        </div>
                      </div>
                      <div className="product_count">
                        Итог: {item.amount * item.count}р.
                      </div>
                      <Button
                        size={"large"}
                        onClick={() => removeFromCart(item)}
                      >
                        Удалить из корзины
                      </Button>
                    </List.Item>
                  );
                } else {
                  {
                    console.log("NOT BILL ITEM");
                  }
                  return (
                    <List.Item>
                      <List.Item.Meta
                        style={{ alignItems: "center" }}
                        avatar={
                          <Image
                            width={"100px"}
                            height={"100px"}
                            src={item.photo}
                          />
                        }
                        title={item.name}
                      />
                      <div className="amount-count">
                        <div className="product_price">
                          Цена: {item.amount}р.
                        </div>
                        <div className="product_count">
                          Количество: {item.count}
                        </div>
                      </div>
                      <div className="product_count">
                        Итог: {item.amount * item.count}р.
                      </div>
                      <Button
                        size={"large"}
                        onClick={() => removeFromCart(item)}
                      >
                        Удалить из корзины
                      </Button>
                    </List.Item>
                  );
                }
              }}
            />
          </ConfigProvider>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {items.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="product_total">Итого: {totalPrice}р.</div>
                <Button type="primary" size="large" onClick={addCartToOrder}>
                  Оформить заказ
                </Button>
              </div>
            )}
          </Col>
        </Col>
      </Row>
    </Layout.Content>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  cart: state.cartReducer,
});

export default connect(mapStateToProps, { removeFromCart, takeCart })(CartPage);
