import {
  ADD_USER_INFO,
  CLEAR_ORDERS,
  CREATE_ORDER,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  TAKE_CART,
} from "../actions/types";

const initialState = {
  order: {
    items: [],
  },
  loading: false,
  orders: [],
  err: null,
};
export default function orderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TAKE_CART:
      return {
        ...state,
        order: { items: payload },
      };
    case ADD_USER_INFO:
      return {
        ...state,
        order: { ...state.order, userInfo: payload },
      };
    case GET_ORDERS:
    case CREATE_ORDER:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: payload,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
      };
    case GET_ORDERS_FAILURE:
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        err: payload,
      };
    default:
      return state;
  }
}
